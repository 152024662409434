/* chatbot/gui/src/App.css */

body {
    margin: 0;
    font-family: 'Alegreya', 'Arial Narrow', Arial, sans-serif;
}

input {
    font-family: 'Alegreya', 'Arial Narrow', Arial, sans-serif;
}

.mainContainer {
    background-color: #f6f7fb;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.mainContainerInner {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.heading {}

.messageBox {
    height: 400px;
    overflow-y: auto;
    padding: 10px;
    /* margin-bottom: 20px; */
    border-radius: 12px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.message {
    margin: 15px 10px;
    padding: 10px;
    display: flex;
}

.messageRight {
    background-color: #fff3eb;
    /* background-color: rgb(250 229 203); */
    text-align: right;
    border-radius: 10px;
    border-top-right-radius: 0px !important;
    margin-left: 130px;
    width: fit-content;
    padding: 10px;
    min-width: 50px;
}

.messageRight p {
    text-align: left;
}

.messageLeft {
    background-color: #f6f7fb;
    text-align: left;
    border-radius: 10px;
    border-top-left-radius: 0px !important;
    margin-right: 130px;
    padding: 10px;
}

.messageRight p {
    margin-block-start: 0.3em;
    margin-block-end: 0.3em;
}

.inputContainer {
    padding: 15px 20px 20px 20px;
    border-radius: 0px 0px 10px 10px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    display: flex;
    margin-bottom: 10px
}

.inputContainer input {
    border-radius: 15px;
    height: 30px;
    border: 1px solid #ede6e6;
    /* border: 1px solid #9b94bc; */
    font-size: 16px;
    padding: 10px 15px 10px 15px;
    flex-grow: 1;
    margin-right: 15px;
    background-color: #f6f7fb;
    /* font-family: 'Alegreya'; */
}

.inputContainer input:focus {
    border-color: #726aa1;
}

.inputContainer input:focus-visible {
    border: 2px solid #726aa1 !important;
}

.inputContainer input:active {
    border-color: #726aa1;
}

.inputContainer button {
    border-radius: 50%;
    padding: 12px 10px 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileIcon {
    margin-right: 10px;
}

.brandingSection {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}

.brandingSection p {
    color: #758195;
}

.noMessageContainer {
    align-content: center;
    width: 100%;
    height: 100%;
}

.noMessageContainerInner {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.noMessageContainerInner img {
    width: 160px;
    height: 160px;
    margin: auto;
    opacity: 0.7;
}

.noMessageContainerInner p {
    font-size: 20px;
    font-weight: 500;
    opacity: 0.7;
}

.noMessages {
    text-align: center;
}


/* Responsive section */
@media only screen and (max-width: 600px) {
    .mainContainer {
        /* background-color: #403a5c; */
        background-color: #626f86;
    }

    .heading {
        color: #ffffff;
        line-height: 2.8em;
        margin-block-end: 0em;

    }

    .messageBox {
        height: calc(100vh - 189px);
        border-radius: 0px;
    }

    .message {
        /* margin: 0px; */
        padding: 0px;
    }

    .mainContainerInner {
        width: 100%;
        padding: 0px;
        margin: 0px;
        height: 100vh;
    }

    .messageRight {
        margin-left: 100px;
    }

    .messageLeft {
        margin-right: 100px;
    }

    .inputContainer {
        border-radius: 0px;
        margin-bottom: 0px;
        padding: 15px 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    }

    .inputContainer input {
        margin-right: 10px;
    }

    .brandingSection {
        display: none;
    }
}